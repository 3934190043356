<template>
  <div>
    <b-card-code title="Add Bundle">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <div align="center" class="text-center">
                <h4>Cover</h4>
                <b-media class="mb-1 mr-auto">
                  <b-img
                    class=""
                    ref="previewEl"
                    :src="img"
                    width="400px"
                    height="300px"
                  />
                </b-media>
                <div class="mb-4">
                  <b-button variant="primary" @click="$refs.refInputEl.click()">
                    <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @change="uploadImage"
                    />
                    <feather-icon icon="EditIcon" class="" />
                  </b-button>
                </div>
              </div>
            </b-col>

            <!-- title -->
            <b-col md="6">
              <b-form-group label="Title" label-for="title">
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="bundleData.title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Start Date -->
            <b-col md="6">
              <b-form-group label="Start Date" label-for="start date">
                <validation-provider
                  #default="{ errors }"
                  name="start date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="bundleData.startdate"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- End Date -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="end date"
                rules="required"
              >
                <b-form-group label="End Date" label-for="end date">
                  <flat-pickr
                    v-model="bundleData.enddate"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- type -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="type"
                rules="required"
              >
                <b-form-group
                  label="Type"
                  label-for="type"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="type"
                    v-model="typeSelect"
                    :state="typeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- usernumbers -->
            <b-col md="6">
              <b-form-group label="User Numbers" label-for="user numbers">
                <validation-provider
                  #default="{ errors }"
                  name="user numbers"
                  rules="required|integer"
                >
                  <b-form-input
                    id="usernumbers"
                    v-model="bundleData.usernumbers"
                    :state="errors.length > 0 ? false : null"
                    placeholder="User Numbers"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!--submit button -->
            <b-col>
              <b-button
                size="lg"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import { required, integer } from '@validations'
import { VueEditor } from 'vue2-editor'
export default {
  components: {
    
    VueEditor,
    BCardCode,
  },
  
  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      ProfilePictureBase64: [],
      img: '',
      id: 0,
      cost: '',
      total: '',
      bundleData: {
        type: '',

        startdate: '',
        enddate: '',
        usernumbers: '',
        title: '',
      },
      typeSelect: {
        text: '',
        value: '',
      },
      typeOptions: [
        {
          value: 'percentage',
          text: 'Percentage',
        },
        {
          value: 'fixed_price',
          text: 'Fixed Price',
        },
      ],

      errors_back: [],
      required,
      integer,
    }
  },
  created() {
    this.id = this.$route.params.id

    axios
      .get('bundles/' + this.id)
      .then((result) => {
        const data = result.data.data

        this.bundleData.startdate = data.startdate
        this.bundleData.enddate = data.enddate
        this.bundleData.usernumbers = data.usernumbers

        this.bundleData.title = data.title
        this.img = data.cover
        if (data.type == 'percentage') {
          this.typeSelect.text = 'Percentage'
          this.typeSelect.value = 'percentage'
        } else {
          his.typeSelect.text = 'Fixed Price'
          this.typeSelect.value = 'fixed_price'
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.img.includes(',')) {
            this.bundleData.cover = this.img
              .split(',')
              .map((item) => item.trim())
            this.bundleData.cover = this.bundleData.cover[1]
          }
          this.bundleData.type = this.typeSelect.value

          axios
            .put('bundles/' + this.id, this.bundleData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    // cencode cover
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        this.img = e.target.result
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>
<style lang="scss">


</style>